import React from 'react';
import {ContDevs, GlobalStyleDevs, TituloDevs} from './styles';
import {Column, Columns, Container} from 'bloomer';
import {ItemDev} from './ItemDev';
import devIco1 from '../../images/oxean/ico.svg';
import devLogo1 from '../../images/oxean/logotipo.svg';
import devIco2 from '../../images/punta-mar/ico.svg';
import devLogo2 from '../../images/punta-mar/logotipo.svg';
import devIco3 from '../../images/zul/ico.svg';
import devLogo3 from '../../images/zul/logotipo.svg';
import FooterCopmp from '../Footer';

export const Devs = () => {

  return (
    <div className="cont-page page-devs">
      <GlobalStyleDevs/>
      <TituloDevs><span>Nuestros</span> Desarrollos</TituloDevs>
      <Container>
        <Columns className="columns-responsive full-height">
          <Column
            className="no-display-mobile"
            isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}
          >&nbsp;</Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 8, widescreen: 8}}>

            <ItemDev
              index={1}
              ico={devIco1}
              logo={devLogo1}
              ubicacion={'Playa Bacocho, Fracc. Bacocho Puerto Escondido'}
              linkDev={'oxean'}
              coordenadas={'15.8657047,-97.0924109'}
              linkMaps={'https://www.google.com/maps/place/Oxean+Puerto+Escondido/@15.8657047,-97.0924109,17z/data=!4m5!3m4!1s0x85b8f7df0b328f83:0xc9f6c1872e0a3ccd!8m2!3d15.8657047!4d-97.0902222?hl=es-MX'}
              status={'Preventa'}
            />

            <ItemDev
              index={2}
              ico={devIco2}
              logo={devLogo2}
              ubicacion={'Avenida Los Delfines Fracc. del Sol'}
              linkDev={'puntamar'}
              coordenadas={'15.8918618,-97.1188322'}
              linkMaps={'https://www.google.com/maps?q=15.8918618,-97.1188322&hl=es-MX&gl=mx&entry=gps&lucs=s2se&shorturl=1'}
              status={'Preventa'}
            />

            <ItemDev
              index={3}
              ico={devIco3}
              logo={devLogo3}
              ubicacion={'Calle Ceiba, Fracc. San Agustin, Colonia Emiliano Zapata'}
              linkDev={'zul'}
              coordenadas={'15.8657047,-97.0924109'}
              linkMaps={`https://www.google.com/maps/place/15°50'44.8"N+97°02'31.0"W/@15.8457893,-97.0441353,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xde9a1e88790aec14!8m2!3d15.8457893!4d-97.0419466?hl=es-MX`}
              status={'Proximamente'}
            />

          </Column>
          <Column
            className="no-display-mobile"
            isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}
          >&nbsp;</Column>
        </Columns>
      </Container>
      <FooterCopmp/>
    </div>
  );
};
