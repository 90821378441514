import React from 'react';
import {Column, Columns, Image} from 'bloomer';
import {
  ContCoordenadas,
  ContCoordStatus,
  ContItemDev,
  ContItemDevIco, ContItemDevInfo,
  ContItemDevLogo,
  InfoSectionItemDev, ItemDevLogo,
  LineVertical, LinkItemDevLogo,
  TitleSectionItemDev,
} from './styles';

export const ItemDev = ({index, ico, logo, linkDev, ubicacion, coordenadas, linkMaps, status}) => {

  return (
    <ContItemDev>
      <Columns className={index % 2 === 0 ? 'reorder-column' : ''}>
        <Column isSize={{mobile: 12, tablet: 12, desktop: 4, widescreen: 4}}>
          <ContItemDevIco>
            <LinkItemDevLogo to={`/${linkDev}/`}>
              <Image src={ico}/>
            </LinkItemDevLogo>
          </ContItemDevIco>
        </Column>
        <Column isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
          <LineVertical/>
        </Column>
        <Column isSize={{mobile: 12, tablet: 12, desktop: 7, widescreen: 7}}>
          <ContItemDevInfo>
            <ContItemDevLogo>
              <LinkItemDevLogo to={`/${linkDev}/`}>
                <ItemDevLogo src={logo}/>
              </LinkItemDevLogo>
            </ContItemDevLogo>
            <TitleSectionItemDev>Ubicación</TitleSectionItemDev>
            <InfoSectionItemDev>{ubicacion}</InfoSectionItemDev>
            <Columns>
              <Column isSize={{mobile: 12, tablet: 8, desktop: 8, widescreen: 8}} className={'padding-no-responsive'}>
                <ContCoordenadas>
                  <TitleSectionItemDev>Coordenadas</TitleSectionItemDev>
                  <InfoSectionItemDev>
                    <a href={linkMaps} target="_blank">{coordenadas}</a>
                  </InfoSectionItemDev>
                </ContCoordenadas>
              </Column>
              <Column isSize={{mobile: 0, tablet: 1, desktop: 1, widescreen: 1}} className={'padding-no-responsive'}>
                <LineVertical/>
              </Column>
              <Column isSize={{mobile: 12, tablet: 3, desktop: 3, widescreen: 3}} className={'padding-no-responsive'}>
                <div>
                  <TitleSectionItemDev>Status</TitleSectionItemDev>
                  <InfoSectionItemDev>{status}</InfoSectionItemDev>
                </div>
              </Column>
            </Columns>
          </ContItemDevInfo>
        </Column>
      </Columns>
    </ContItemDev>
  )
}
