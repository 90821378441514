import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {Devs} from '../components/Devs';

const DesarrollosPage = () => {
  return (
    <Layout>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <Devs/>

    </Layout>
  )
}

export default DesarrollosPage
