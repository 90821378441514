import styled, {createGlobalStyle} from 'styled-components';
import {Link} from "gatsby";
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const GlobalStyleDevs = createGlobalStyle`
  .page-devs{
    height: auto !important;
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .reorder-column{
    flex-direction: row-reverse;
  }
`
export const ContDevs = styled.div`
  position: relative;
  margin: 0 auto;
`
export const TituloDevs = styled.div`
  font-family: ${GlobalFonts.fontSecondary};
  color: ${GlobalColors.colorPrimary};
  text-align: center;
  
  & > span{
    color: ${GlobalColors.colorLinkHover};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 25px;
    font-size: 60px;
    line-height: 60px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 25px;
    font-size: 70px;
    line-height: 80px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 35px;
    font-size: 80px;
    line-height: 90px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 50px;
    font-size: 100px;
    line-height: 110px;
  }
  @media(min-width: 1408px){
    margin-top: 50px;
    font-size: 100px;
    line-height: 110px;
  }
`
export const ContItemDev = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 35px;
`
export const ContItemDevInfo = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const TitleSectionItemDev = styled.div`
  position: relative;
  margin-bottom: 10px;
  font: 18px '${GlobalFonts.fontLight}';
  color: ${GlobalColors.colorLinkHover};
`
export const InfoSectionItemDev = styled.div`
  position: relative;
  margin-bottom: 15px;
  font: 16px '${GlobalFonts.fontRegular}';
  color: ${GlobalColors.colorMenuPrimary};

  & > a {
    color: ${GlobalColors.colorPrimary};
  }
  & > a:hover {
    color: ${GlobalColors.colorLinkHover};
  }
`
export const ContItemDevIco = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px;
  border: 1px solid ${GlobalColors.colorLinkHover};
  border-radius: 50%;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 70%;
    margin: 0 auto;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
    margin: 0 auto;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const LineVertical = styled.div`
  position: relative;
  width: 1px;
  height: 80%;
  top: 10%;
  margin: 0 auto;
  background-color: ${GlobalBackgroundColors.bgGreyLight};
`
export const ContItemDevLogo = styled.div`
  position: relative;
  width: auto;
  height: 70px;
  margin-bottom: 20px;
`
export const LinkItemDevLogo = styled(Link)`
  
`
export const ItemDevLogo = styled.img`
  position: relative;
  width: auto;
  height: 100%;
`
export const ContCoordStatus = styled.div`
  position: relative;
  width: auto;
  height: 65px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`
export const ContCoordenadas = styled.div`
  margin-right: 5px
`
